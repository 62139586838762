@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.sa-card-wrap
{
  width:100%;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items:space-around;
  padding: 2rem 0;
}

.sa-tile
{
  width:250px;
  height:175px;
  margin:10px;
  text-align: center;
  display:inline-block;
  background-size:cover;
  position:relative;
  transition: all 0.4s ease-out;
  overflow:hidden;
  color:white;
  font-family:'Roboto';
  border-radius: 10px;

  -webkit-box-shadow: 10px 10px 17px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 17px -3px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 17px -3px rgba(0,0,0,0.75);

}

.sa-tile img
{
  height:100%;
  width:100%;
  position:absolute;
  top:0;
  left:0;
  z-index:0;
  transition: all 0.4s ease-out;
  border-radius: 10px;
  box-shadow: 10px 5px 5px rgb(129, 129, 129);
}

.sa-tile .sa-text
{
/*   z-index:99; */
  position:absolute;
  padding:30px;
  height:calc(100% - 60px);
}



.sa-tile h5 {
  color: white;
  font-weight:300;
  margin-bottom: .5rem;
  text-shadow: 2px 2px 10px rgba(0,0,0,0.3);
}

.sa-tile .sa-text h1 {
  color: rgb(255, 255, 255);
  font-weight:300;
  margin:0;
  text-shadow: 2px 2px 10px rgba(0,0,0,0.3);
}



.sa-tile h2
{
  font-weight:100;
  margin:20px 0 0 0;
  font-style:italic;
   transform: translateX(200px);
}



.sa-tile p
{
  font-weight:300;
  margin:20px 0 0 0;
  line-height: 25px;
  opacity:0;
  transform: translateX(-200px);
  transition-delay: 0.2s;
}

.sa-animate-text
{
  opacity:0;
  transition: all 0.6s ease-in-out;
}

.sa-tile:hover {
/*   background-color:#99aeff; */
box-shadow: 0px 35px 77px -17px rgba(0,0,0,0.64);
  transform:scale(1.05);
} 

.sa-tile:hover img {
  opacity: 0.2;
} 

.sa-tile:hover .sa-animate-text {
  transform:translateX(0);
  opacity:1;
}


.sa-tile:hover span
{
  opacity:1;
  transform:translateY(0px);
}


.sa-cards-header {
    width: auto;
    padding: 20px;
    text-align: center;
    margin: 2rem auto;
}

.sa-cards-header h1 {
    margin: 2rem auto;
    font-family: 'Montserrat', sans-serif;
}

.sa-cards-header p {
  font-family: 'Montserrat', sans-serif;
}


.sa-tile button {
  padding: .5rem 2rem;
  font-size: 12px;
  border-radius: 5px;

}

@media (max-width: 940px) {
  .sa-card-wrap {
    width: 100%;
  }

  .sa-tile { 
    text-align: center;
    width: 250px;
    height:170px;
  }

  .sa-tile h1 {
    font-size: 1.5rem;
  }

}

@media (max-width: 700px) {



  .sa-tile
  {
    width:150px;
    height:190px;  
  }

  
  .sa-tile h1 {
  color: white;
  font-weight:300;
  margin-bottom: .5rem;
  text-shadow: 2px 2px 10px rgba(0,0,0,0.3);
  font-size: 1rem;
}

.sa-tile button {
  padding: .2rem 2rem;
  font-size: 12px;
}

}