@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.wrapper1 {
    padding: 1rem 0 8rem 0;
    background: #ffffff;
}

.wrapper-content {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
}

.wrapper-content-left {
    width: 50%;
    text-align: center;
}

.wrapper-content-left img {
    width: 80%;
}

.wrapper-content-right {
    width: 600px;
    margin: auto;
    padding: 0 2rem;
}

.wrapper-content-right h2 {
    font-size: 2rem;
    color: #5a5c82;
}

.wrapper-content-right h1 {
    text-transform: uppercase;
    color: #977ab3;
    font-size: 1rem;
    letter-spacing: 8px; 
}


@media screen and (max-width:1250px)  {
    .wrapper-content-right {
        width: 100%;
        padding: 1rem 2rem;
    }

    .wrapper-content-right h1, h2, p {
        text-align: center;
    }

    .wrapper-content-right h2 {
        font-size: 2rem;
    }

}

@media screen and (max-width:940px) {
    .wrapper-content-left {
        width: 100vw;
    }
    .wrapper-content-left img {
        padding: 2rem;
    }
}
