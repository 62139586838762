@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');


.hero {
    width: 100%;
    height: 90vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #1516226c;
  }
  
  .hero:before {
    content: '';
    position: absolute;
    background: url('../../assets/hero-bg-min.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.hero .hero-container {
    position: absolute;
    padding: 1rem;
    left: 5%;
    top: 40%;
}

.hero-logo {
    text-align: center;
}

.hero h1 {
    font-size: 4.5rem;
    padding: 0 1rem;
    text-align: center;
    color: white;
    font-family: 'Montserrat', sans-serif;
    line-height: 4rem;
}

.hero h2 {
    font-size: 1.5rem;
    text-align: center;
    color: white;
}

.hero-container p {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: white;
    text-transform: uppercase;
    padding: .5REM 1rem ;
}

/* .hero .hero-btn button {
    cursor: pointer;
} */

/* button */

.hero-btn {
    width: 100%;
    text-align: center;
}

.hero-cta {
    position: relative;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    justify-content: center;
   }
   
   .hero-cta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: #5a5c82;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
   }
   
   .hero-cta span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #f0f1f1;
   }
   
   .hero-cta svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #f0f1f1;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
   }
   
   .hero-cta:hover:before {
    width: 100%;
    background: #5a5c82;
   }
   
   .hero-cta:hover svg {
    transform: translateX(0);
   }
   
   .hero-cta:active {
    transform: scale(0.95);
   }


/* MEDIA QUERIES */

@media screen and (max-width:650px) {

    .hero h1 {
    font-size: 2.5rem;
    line-height: 2.6rem;
    }

    .hero h2 {
        font-size: 1rem;
    }

    .hero p {
        font-size: 14px;
    }


}
