@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.mh-card-wrap
{
  width:100%;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items:space-around;
}

.mh-tile
{
  width:300px;
  height:300px;
  margin:10px;
  /* background-color:#444549; */
  display:inline-block;
  background-size:cover;
  position:relative;
  transition: all 0.4s ease-out;
  /* box-shadow: 0px 35px 77px -17px rgba(0,0,0,0.44); */
  overflow:hidden;
  color:white;
  text-align: center;
  font-family:'Roboto';
  border-radius: 10px;
  /* box-shadow: 10px 5px 5px rgb(129, 129, 129); */
}

.mh-tile img
{
  height:100%;
  width:100%;
  position:absolute;
  top:0;
  left:0;
  z-index:0;
  transition: all 0.4s ease-out;
  border-radius: 10px;
  box-shadow: 10px 5px 5px rgb(129, 129, 129);
}

.mh-tile .mh-text
{
/*   z-index:99; */
  position:absolute;
  padding:30px;
  height:calc(100% - 60px);
}
.mh-tile h1{
  color: rgb(255, 255, 255);
  font-weight:300;
  margin:0;
  text-shadow: 2px 2px 10px rgba(0,0,0,0.3);
  font-size: 1.5rem;
}

.mh-tile h2
{
  font-weight:100;
  margin:20px 0 0 0;
  font-style:italic;
   transform: translateX(200px);
}
.mh-tile p
{
  font-weight:300;
  margin:20px 0 0 0;
  opacity:0;
  transform: translateX(-200px);
  transition-delay: 0.2s;
  font-size: 12px;
}
.mh-animate-text
{
  opacity:0;
  transition: all 0.6s ease-in-out;
}

.mh-tile:hover {
/*   background-color:#99aeff; */
box-shadow: 0px 35px 77px -17px rgba(0,0,0,0.64);
  transform:scale(1.05);
} 

.mh-tile:hover img {
  opacity: 0.2;
} 

.mh-tile:hover .mh-animate-text {
  transform:translateX(0);
  opacity:1;
}


.mh-tile:hover span
{
  opacity:1;
  transform:translateY(0px);
}


.mh-cards-header {
    width: auto;
    padding: 20px;
    text-align: center;
    margin: 2rem auto;
}

.mh-cards-header h1 {
  margin: 2rem auto;
  font-family: 'Montserrat', sans-serif;
}

.mh-cards-header p {
font-family: 'Montserrat', sans-serif;
}


@media (max-width: 940px) {
  .mh-card-wrap {
    width: 100%;
  }

}

