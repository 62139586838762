@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.fentanyl-section {
  padding:  3rem 0 0 0;
}

.fentanyl-section-content {
    padding: 20px;
    text-align: justify;
}




/* MEDIA QUERIES */

@media screen and (max-width:940px) {

  .fentanyl-section-content h1 {
      font-size: 2rem;
      text-align: center;
  }
  
  }