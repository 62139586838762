.contactform {
  background: #ffffff00;
  padding: 3rem 0;
}


  .get_form_inner {
    display: block;
    padding: 3rem 3rem ;
    background: #5a5c82;
    box-shadow: -4px -2px 20px -7px #cfd5df;
    margin: 2rem 5rem 2rem 5rem;
    border-radius: 10px;
  }

input {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

  input[type="text"], input[type="text"], input[type="email"], input[type="tel"] {
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    color: #333;
    height: 42px;
    padding: 0 0 0 20px;
    width: 100%;
    outline: 0;
  }

  textarea {
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    color: #333;
    padding: 12px 0 0 20px;
    width: 100%;
    outline: 0;
    margin-bottom: 20px;
    resize: none;
  }

  .get_form_inner_text h3 {
    color: #f7f2f2;
    font-size: 26px;
    font-weight: 600;
  }

  .get_form_inner_text p {
    text-align: left;
    color: #f7f2f2;
  }

  input[type="submit"] {
    background-color: rgba(223, 137, 8, 0.753);
    border: var(--primary-color);
    color: #fff;    
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    /* background: transparent; */
    /* border: 2px solid; */
    font-weight: 500;
    padding: 10px 20px;
    outline: 0;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -moz-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -ms-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -o-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  input[type="submit"]:hover {
    background-color: white;
    border-color: white;
    color: #0c0c0c;
  }


  .contact-cards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .get_form_inner2 {
    display: block;
    width: 300px;
    height: 150px;
    padding: 2rem 1rem;
    background: #fff;
    box-shadow: -4px -2px 20px -7px #cfd5df;
    margin: 1rem 1rem 3rem 1rem;
  }

  .get_form_inner2  h2 {
    color: var(--primary-color);
    text-align: center;
    }
    
    .get_form_inner2 p, .get_form_inner2 a {
    color: rgb(39, 38, 38);
    text-align: center;
    margin: 1rem auto;
    }

    iframe {
      width: 100%;
      border-radius: 1rem;
      border: none;
      box-shadow: 10px 5px 5px rgb(129, 129, 129);

    }


    .textarea2 textarea {
      border: none;
      color: rgb(53, 82, 42);
      text-align: center;
    }
    
    .hidden {
      display: none;
      margin-bottom: 2rem;
      color: white;
      text-align: center;
      width: 100%;
      margin: auto;
      z-index: 2;
    }

  

  @media screen and (max-width: 940px) {
    .get_form_inner {
        margin: 2rem 1rem 2rem 1rem;
      }

      iframe {
        display: none;
      }
    }
  
