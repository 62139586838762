@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.meth-section-header {
    width: auto;
    padding: 20px;
    text-align: center;
    margin: 2rem auto;
}

.meth-section-header h1 {
    font-family: 'Montserrat', sans-serif;
    text-align: left;
}

.meth-section-header p {
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
}


