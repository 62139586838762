@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');

.top-flex {
  position: relative;
  display: flex;
  gap: 1rem;
  padding: 3rem 5rem;
  margin: -5rem 3rem 0 3rem;
  background-color: rgb(255, 255, 255);
}

.top-flex-item {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.top-flex-icon {
  width: 200px;
}

.top-flex-icon img{
  width: 100%;
}

.top-flex-text h1 {
  font-size: 1rem;
  color: #977ab3;
  padding: 1rem 0;
  text-align: left;
}

.top-flex-text p {
  font-size: 14px;
  color: #5a5c82;
  text-align: left;
}


@media screen and (max-width: 1160px) {

  .top-flex {
    flex-wrap: wrap ;
    /* margin: -5rem 3rem 0 3rem; */

  }

  .top-flex-item {
    width: 40%;
    margin: auto;
  }
}



@media screen and (max-width: 940px) {
  .top-flex {
    display: none;
  }

}



