@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.mh-php {
    width: 100%;
    height: 50vh;
    background: rgba(22, 22, 22, 0.658);
    position: relative;
  }
  
  .mh-php:before {
    content: '';
    position: absolute;
    background: url('../../assets/mh-submenu-bg-min.jpg') no-repeat center center/cover;
    height: 50vh;
    width: 100%;
    z-index: -1;
  }
  


.mh-php {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.mh-php .mh-php-content {
    display: block;
    position: absolute;
    bottom: 10%;
    padding: 1rem;
}



.mh-php .mh-php-content h1 {
    color: white;
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.mh-php h2 {
    color: white;
    font-size: 1.2rem;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    .mh-php .mh-php-content h1 {
        font-size: 2rem;

    }

    .mh-php .mh-php-content h2 {
        font-size: 1rem;
    }


}