.topbar {
    width: 100%;
    padding: 1rem;
    background-color: #5a5c82;
}

.topbar .topbar-content {
    display: flex;
    justify-content: space-around;
    color: white;
}

.topbar .topbar-content p {
    padding: 0 1rem;
    font-size: 13px;
}

.topbar .bar-box1 {
    width: 28rem;
    border-right: 1px solid rgba(255, 255, 255, 0.459);
}

.topbar .bar-box2 {
    width: 17rem;
    border-right: 1px solid rgba(255, 255, 255, 0.459);
}

.topbar span {
    color: #ac87cf;
    font-size: 16px;
    font-weight: bold;
}

@media screen and (max-width: 940px) {
    /* .topbar {
        display: none;
    } */

    .topbar .topbar-content p {
        padding: 0 1rem;
        font-size: 11px;
    }

    .topbar .bar-box1 {
        width: 25rem;
    }





}

@media screen and (max-width: 870px) {
    .top-hide {
        display: none;
    }

    .topbar .topbar-content p {
        font-size: 13px;
    }
}