@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.contact-hero {
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(21, 3, 27, 0.521);
  }
  
  .contact-hero:before {
    content: '';
    position: absolute;
    background: url('../../assets/menu-bg-contact-min.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  
.contact-hero .contact-hero-content {
    display: block;
    position: absolute;
    top: 35%;
    padding: 1rem;
}

.contact-hero h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
}

.contact-hero h2 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    letter-spacing: 2px;
}


.contact-hero p {
    font-family: 'Montserrat', sans-serif;
    font-family: 'Montserrat', sans-serif;
    color: white;
}


/* MEDIA QUERIES */

@media screen and (max-width:1200px) {

.contact-hero h2 {
    font-size: 1.5rem;
    text-align: center;
    padding: 1rem 0;
}

.contact-hero h1 {
    font-size: 2.5rem;
    text-align: center;
}

.contact-hero p {
    text-align: center;
}

}