
.sa-iop {
    width: 100%;
    height: 50vh;
    background: rgba(21, 3, 27, 0.521);
    position: relative;
  }
  
  .sa-iop:before {
    content: '';
    position: absolute;
    background: url('../../assets/sa-submenu-bg-min.webp') no-repeat center top/cover;
    height: 50vh;
    width: 100%;
    z-index: -1;
  }


  .sa-iop {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.sa-iop .sa-iop-content {
    display: block;
    position: absolute;
    bottom: 10%;
    padding: 1rem;
}



.sa-iop .sa-iop-content h1 {
    color: white;
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.sa-iop h2 {
    color: white;
    font-size: 1.2rem;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    .sa-iop .sa-iop-content h1 {
        font-size: 2rem;

    }

    .sa-iop .sa-iop-content h2 {
        font-size: 1rem;
    }


}