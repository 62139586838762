@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.cocaine {
    width: 100%;
    height: 80vh;
    background: #14141475;
    position: relative;
  }
  
  .cocaine:before {
    content: '';
    position: absolute;
    background: url('https://rodeo-recovery-substance-abuse.s3.us-west-1.amazonaws.com/cocaine.jpg') no-repeat center center/cover;
    height: 80vh;
    width: 100%;
    z-index: -1;
  }
  

.cocaine {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.cocaine .cocaine-content {
    display: block;
    position: absolute;
    top: 40%;
    padding: 1rem;
}

.cocaine h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
}

.cocaine h2 {
    text-align: center;
    color: white;
    font-size: 1rem;
    letter-spacing: 2px;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.cocaine h1 {
    font-size: 2.5rem;
}



}