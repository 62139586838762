.footer {
    width: 100%;
    height: auto;
    padding: 1rem 0;
    background: #5a5c82;
}


.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo-footer {
    width: 250px;
}

.logo-footer img {
    width: 100%;
}

.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    padding: 2rem ;
}

.footer .top .logo-footer {
    display: flex;
    align-items: center;
}

.footer .top .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color:white;
    cursor: pointer;
}

.footer h2 {
    font-size: 2rem;
}

.footer h3 {
    color: white;
}

.footer .col-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
}

.col {
    width: 300px;
}



.footer h3 {
    margin: 1rem 0;
    text-transform: uppercase;
    text-align: center;
    font-size: 1rem;
    color: rgb(223, 138, 11);
}


.footer p {
    margin: .5rem 0;
    color: white;
    text-align: center;
}


.copyright p {
    color: white;
    text-transform: uppercase;
    text-align: center;
}


.certification {
    justify-content: center;
}

.certificationLeft {
    width: 100%;
    text-align: center;
    margin: auto;
}

.certification h3 {
    text-align: center;
    font-size: .8rem;
}

.certification p {
    text-align: center;
    color: white;
}

.certificationLeftImage {
    width: 130px;
    margin: auto;
    padding: 1rem;
}

.certificationLeftImage img{
    width: 100%;
}

.certification a:hover {
    text-align: center;
    color: rgb(10, 208, 235);
}



@media screen and (max-width: 940px) {
    .footer {
        height: 100%;
    }

}
