@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

/* HEADER */

.mh-cards-header {
  width: auto;
  padding: 20px;
  text-align: center;
  margin: 2rem auto;
}

.mh-cards-header h1 {
margin: 2rem auto;
font-family: 'Montserrat', sans-serif;
}

.mh-cards-header p {
font-family: 'Montserrat', sans-serif;
}


@media (max-width: 940px) {
.mh-card-wrap {
  width: 100%;
}

}


/* IOP PHP OP  */

.mh-card-wrap
{
  width:100%;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items:space-around;
}

.mh-tile
{
  width:300px;
  height:300px;
  margin:10px;
  display:inline-block;
  background-size:cover;
  position:relative;
  transition: all 0.4s ease-out;
  overflow:hidden;
  color:white;
  text-align: center;
  font-family:'Roboto';
  border-radius: 10px;
}

.mh-tile img
{
  height:100%;
  width:100%;
  position:absolute;
  top:0;
  left:0;
  z-index:0;
  transition: all 0.4s ease-out;
  border-radius: 10px;
  box-shadow: 10px 5px 5px rgb(129, 129, 129);
}

.mh-tile .mh-text {
  position:absolute;
  padding:30px;
  height:calc(100% - 60px);
}

.mh-tile h1 {
  color: rgb(255, 255, 255);
  font-weight:300;
  margin:0;
  text-shadow: 2px 2px 10px rgba(0,0,0,0.3);
  font-size: 1.5rem;
}


.mh-tile p {
  font-weight:300;
  margin:20px 0 0 0;
  opacity:0;
  transform: translateX(-200px);
  transition-delay: 0.2s;
  font-size: 12px;
}

.mh-animate-text
{
  opacity:0;
  transition: all 0.6s ease-in-out;
}

.mh-tile:hover {
  box-shadow: 0px 35px 77px -17px rgba(0,0,0,0.64);
  transform:scale(1.05);
} 

.mh-tile:hover img {
  opacity: 0.2;
} 

.mh-tile:hover .mh-animate-text {
  transform:translateX(0);
  opacity:1;
}

/* MENTAL HEALTH CARDS */


.menu-mh {
  padding: 3rem 0;
}

.menu-mh-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.menu-mh-flexItem {
  width: 300px;
  height: auto;
  border: 1px solid rgba(18, 102, 172, 0.171);
  padding-bottom: 1rem;
  box-shadow: 10px 5px 5px rgba(15, 15, 15, 0.274);

}

.menu-mh-flexItem img {
  width: 100%;
}

.menu-mh-flexItem h1 {
  font-size: 1rem;
  text-align: center;
  padding: 1rem 0 .5rem 0;
}

.menu-mh-flexItem h2 {
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.menu-mh-btn {
  text-align: center;
}

.menu-mh-btn button {
  background-color: #5a5c82;
  border: none;
  border-radius: 5px;
  font-size: .8rem;
  padding: .5rem 1.5rem;
}



@media screen and (max-width: 940px) {

}

