@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.iop-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 5rem 1rem;
}

.iop-wrap-left {
    width: 600px;
    margin: auto;

}

.iop-wrap-left h1 {
    font-size: 2rem;
    text-align: center;
    color: rgb(15, 15, 15);
    padding: 1REM;
}

.iop-wrap-right {
    width: 600px;
    margin: auto;
    padding: 0 1rem;
}


.iop-wrap-right p {
    font-size: 16px;
    text-align: justify;
}





/* WRAP 2 */


.iop-wrap2 {
    background: linear-gradient(to top, #00000086, #00000094), url('../../assets/addiction-submenu-bg-min.jpg') no-repeat center center/cover;
    padding: 10rem 0;
}

  .iop-wrap2 h1 {
    font-size: 2rem;
    color: rgb(214, 214, 214);
    text-align: center;
}

.iop-wrap2 p {
    color: rgb(214, 214, 214);
    font-size: 16px;
}

.iop-content {
    padding: 0 2rem;
}

/* MEDIA QUERIES */

@media screen and (max-width:1200px) {
    .iop-wrap-right {
        width: 90%;
    }



}



