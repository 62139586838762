@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.navbar {
    width: 100%;
    height: 110px;
    z-index: 10;
    background: #f8f8f8;
    position: absolute;
}

.navbar .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
}

.navbar-logo {
  z-index: 9999999;
}

.navbar .icon {
  font-size: 1.4rem;
  color: #5a5c82;
}

.navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
}

.hamburger {
    display: none;
    padding: 1rem;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
    padding: 1rem;
}
.navbar li {
    width: max-content;
    padding: .3rem .8rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}


.navbar li a {
  color: #5a5c82;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: bold;

}

  .menu-item .sub__menus li a {
    color: #ffffff;
    font-size: 13px;
    padding: -1rem ;
  }

  .menu-item .sub__menus li a:hover {
    color: #ec9d09 !important;
  }

  li .submenu-header {
    font-size: 14px;
    color: rgb(36, 131, 27);
    font-weight: bolder;
    text-decoration: underline;
    cursor: pointer;
  }


/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: #5a5c82;
    border: 1px solid #5a5c82;
    border-radius: 10px;
    width: auto;
    left: -5rem;
    padding: 2px 0 0 0;
    margin-top: 6px;
    z-index: 1000;
  }



  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows {
    position: relative;
  }

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    position: absolute;
    top: 10px;
  }




@media screen and (max-width: 1200px) {

  .navbar .container {
    justify-content: space-between;

}

    .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .navbar li a {
      color: rgb(250, 250, 250);
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        top: 0;
        left:-100%;
        align-items: center;
        justify-content: center;
        background: rgb(0, 0, 0);
        transition: 0.5s ease-in;
        color: white;
    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1rem;
    }

    .navbar button {
        font-size: 1.8rem;
    }

    .menu-item .sub__menus li a {
        font-size: 14px;
      }

      li .submenu-header {
        font-size: 14px;
      }

      #submenu-item li {
        padding: .2rem 1rem;
      }



}





