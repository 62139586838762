@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.InsuranceSection {
    width: 100%;
    position: relative;
    padding: 5rem 0;
    background: #ffffff;
}

.InsuranceSection .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: 2rem 0;
}

.InsuranceSection-container {
    text-align: center;
    padding: 25px;
}

.InsuranceSection-container p {
        padding: 1rem 0;
        font-family: 'Montserrat', sans-serif;
    }

.InsuranceSection h1 {
    padding: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.insurance-thumbnail {
    width: 150px;
    padding: 1rem;
    -webkit-box-shadow: 0px 0px 27px -18px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 27px -18px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 27px -18px rgba(0,0,0,0.75);

}

.insurance-thumbnail img {
    width: 100%;
    
}

.InsuranceSection .flex-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.insuranceSection-btn button {
    margin: 1rem auto;
}


@media  screen and (max-width:940px) {
    .InsuranceSection-container h1 {
        font-size: 1.5rem;
        padding: 1rem 0;
    }


    .InsuranceSection-container p {
        font-size: 14px;
    }

    .team img {
        width: 350px;
    }

    .insurance-thumbnail {
        width: 100px;
    }

}






