@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.adhd {
    width: 100%;
    height: 80vh;
    background: rgba(15, 15, 15, 0.445);
    position: relative;
  }
  
  .adhd:before {
    content: '';
    position: absolute;
    background: url('../../assets/addiction-submenu-bg-min.jpg') no-repeat center center/cover;
    height: 80vh;
    width: 100%;
    z-index: -1;
  }
  

.adhd {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.adhd .adhd-content {
    display: block;
    position: absolute;
    top: 40%;
    padding: 1rem;
}

.adhd h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
}

.adhd h2 {
    text-align: center;
    color: white;
    font-size: 1rem;
    letter-spacing: 2px;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.adhd h1 {
    font-size: 2rem;
}

}


@media screen and (max-width:500px) {

    .adhd h1 {
        font-size: 1.5rem;
    }
    
    }